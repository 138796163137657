import { Suspense, useState, useMemo, useEffect, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import {
  PerspectiveCamera,
  Environment,
  useProgress,
  CameraControls
} from '@react-three/drei';
import { debounce } from 'lodash';

import PropTypes from 'prop-types';

import stollLogo from '../assets/STOLL_LOGO.gif';
import Car from '../components/Car';
import 'react-rangeslider/lib/index.css';
import '../index.css';

const HDRI_LIST = {
  'canary_wharf': require('../assets/hdri/canary_wharf_2k.hdr'),
  'canary_wharf_4k': require('../assets/hdri/canary_wharf_4k.hdr'),
};

const CAR_OPTIONS_LIST = [
  {
    key: 'audi-rs3-limo',
    img: require('../assets/audi-rs-3.webp'),
    file: require('../assets/cars/11642_Audi_RS3_stoll_001.glb'),
    name: 'Audi RS 3 Limo'
  },
  {
    key: 'audi-rs3-sportback',
    img: require('../assets/audi-rs-3.webp'),
    file: require('../assets/cars/11641_Audi_RS3_stoll_001.glb'),
    name: 'Audi RS 3 Sportback'
  },
];


const INIT_PART_DISPLAY_DATA = {
  'audi-rs3-limo': {
    'Stoll_Flic_Oben': true,
    'Stoll_Flic_Unten': true,

    'oem_Sill': false,
    'Stoll_Sill': true,

    'oem_Fronteinsatze': false,
    'Stoll_Fronteinsatze': true,

    'Stoll_FrontSpiltter': true,

    'Stoll_HeckDiffusorSide': true,

    'oem_Diffusor': false,
    'Stoll_HeckDiffusor': true,

    'Stoll_HeckSpoile': true
  },
  'audi-rs3-sportback': {
    'Stoll_Flic_Oben': true,
    'Stoll_Flic_Unten': true,

    'oem_Sill': false,
    'Stoll_Sill': true,

    'oem_Fronteinsatze': false,
    'Stoll_Fronteinsatze': true,

    'Stoll_FrontSpiltter': true,

    'Stoll_HeckDiffusorSide': true,

    'oem_Diffusor': true,
    'Stoll_HeckDiffuso': true,

    'Stoll_HeckSpoile': true
  }
};

function Configurator({
  carId,

  showFlic,
  showSill,
  showFronteinsatze,
  showFrontSpiltter,
  showDiffusorSide,
  showDiffusor,
  showSpoiler,

  focusState,
  updateFocusState,
  onLoading,
  // resetCamera
}) {
  const camRef = useRef();
  const canvasRef = useRef();
  const cameraControlsRef = useRef(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [selectedPaint, setSelectedPaint] = useState(0);
  const [showDelayLoading, setShowDelayLoading] = useState(false);

  const [carSuspension, setCarSuspension] = useState(0);
  const [carColor, setCarColor] = useState('#410000');

  const [isShowOemSpoiler, setIsShowOemSpoiler] = useState(false);
  const [isFocusSpolier, setIsFocusSpolier] = useState(false);

  const [selectedCarIndex, setSelectedCarIndex] = useState(0);
  const [selectedCarKey, setSelectedCarKey] = useState('');


  // Flic
  const [partDisplayData, setPartDisplayData] = useState(INIT_PART_DISPLAY_DATA[carId]);

  const startDistance = useRef(0);
  const distanceCount = useRef(0);
  const endDistance = useRef(0);

  const { progress, loaded } = useProgress();

  const isMobileAndTabletControlVertical = useMemo(() => windowSize.innerWidth < 800 && window.matchMedia('(orientation: portrait)').matches, [windowSize.innerWidth]);
  const isMobileAndTabletHorizontal = useMemo(() => windowSize.innerWidth < 900 && window.matchMedia('(orientation: landscape)').matches, [windowSize.innerWidth]);

  function Loader() {
    return (
      <img src={stollLogo} alt="stollLogo" className="animate-flicker" />
    )
  }
  function LoadingScreen() {
    return (
      <div className="loading-overlay screen show">
        <Loader />
      </div>
    );
  }

  useEffect(() => {
    if (selectedCarKey === carId) {
      return;
    }

    if (carId) {
      setSelectedCarKey(carId)
      const carIndex = CAR_OPTIONS_LIST.findIndex(item => item.key === carId);
      setSelectedCarIndex(carIndex);
    }
  }, [carId]);


  useEffect(() => {
    if (showFlic === undefined) {
      return;
    }
    if (carId === 'audi-rs3-limo') {
      if (showFlic) {
        setPartDisplayData((prevState) => ({
          ...prevState,
          'Stoll_Flic_Oben': true,
          'Stoll_Flic_Unten': true,
        }))
      } else {
        setPartDisplayData((prevState) => ({
          ...prevState,
          'Stoll_Flic_Oben': false,
          'Stoll_Flic_Unten': false,
        }))
      }
    } else {
      if (showFlic) {
        setPartDisplayData((prevState) => ({
          ...prevState,
          'Stoll_Flic_Oben': true,
          'Stoll_Flic_Unten': true,
        }))
      } else {
        setPartDisplayData((prevState) => ({
          ...prevState,
          'Stoll_Flic_Oben': false,
          'Stoll_Flic_Unten': false,
        }))
      }
    }
  }, [showFlic, carId]);

  useEffect(() => {
    if (showSill === undefined) {
      return;
    }
    if (showSill) {
      setPartDisplayData((prevState) => ({
        ...prevState,
        'oem_Sill': false,
        'Stoll_Sill': true,
      }))
    } else {
      setPartDisplayData((prevState) => ({
        ...prevState,
        'oem_Sill': true,
        'Stoll_Sill': false,
      }))
    }
  }, [showSill])

  useEffect(() => {
    if (showFronteinsatze === undefined) {
      return;
    }
    if (showFronteinsatze) {
      setPartDisplayData((prevState) => ({
        ...prevState,
        'oem_Fronteinsatze': false,
        'Stoll_Fronteinsatze': true,
      }))
    } else {
      setPartDisplayData((prevState) => ({
        ...prevState,
        'oem_Fronteinsatze': true,
        'Stoll_Fronteinsatze': false,
      }))
    }
  }, [showFronteinsatze])

  useEffect(() => {
    if (showFrontSpiltter === undefined) {
      return;
    }
    if (showFrontSpiltter) {
      setPartDisplayData((prevState) => ({
        ...prevState,
        'Stoll_FrontSpiltter': true,
      }))
    } else {
      setPartDisplayData((prevState) => ({
        ...prevState,
        'Stoll_FrontSpiltter': false,
      }))
    }
  }, [showFrontSpiltter])

  useEffect(() => {
    if (showDiffusorSide === undefined) {
      return;
    }
    if (showDiffusorSide) {
      setPartDisplayData((prevState) => ({
        ...prevState,
        'Stoll_HeckDiffusorSide': true,
      }))
    } else {
      setPartDisplayData((prevState) => ({
        ...prevState,
        'Stoll_HeckDiffusorSide': false,
      }))
    }
  }, [showDiffusorSide])

  useEffect(() => {
    if (showDiffusor === undefined) {
      return;
    }
    if (carId === 'audi-rs3-limo') {
      if (showDiffusor) {
        setPartDisplayData((prevState) => ({
          ...prevState,
          'oem_Diffusor': true,
          'Stoll_HeckDiffusor': true,
        }))
      } else {
        setPartDisplayData((prevState) => ({
          ...prevState,
          'oem_Diffusor': true,
          'Stoll_HeckDiffusor': true,
        }))
      }
    } else {
      if (showDiffusor) {
        setPartDisplayData((prevState) => ({
          ...prevState,
          'oem_Diffusor': false,
          'Stoll_HeckDiffuso': true,
        }))
      } else {
        setPartDisplayData((prevState) => ({
          ...prevState,
          'oem_Diffusor': true,
          'Stoll_HeckDiffuso': false,
        }))
      }
    }
  }, [showDiffusor])

  useEffect(() => {
    if (showSpoiler === undefined) {
      return;
    }
    if (showSpoiler) {
      setPartDisplayData((prevState) => ({
        ...prevState,
        'Stoll_HeckSpoile': true,
      }))
    } else {
      setPartDisplayData((prevState) => ({
        ...prevState,
        'Stoll_HeckSpoile': false,
      }))
    }
  }, [showSpoiler])

  useEffect(() => {
    handleOnFocusSpolier(focusState);
  }, [focusState]);

  // useEffect(() => {
  //   handleResetCamera();
  //   console.log(resetCamera);
  // }, [resetCamera])

  useEffect(() => {
    if (onLoading) {
      onLoading(true);
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (progress === 100 && loaded > 0) {
      setShowDelayLoading(true)
      setShowDelayLoadingWithDelay({
        status: false,
        delay: 5000
      });
    } else {
      setShowDelayLoading(true)
    }
  }, [progress, loaded])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function setShowDelayLoadingWithDelay({ status, delay }) {
    if (!delay) {
      setShowDelayLoading(status)
      return;
    }
    setTimeout(() => {
      setShowDelayLoading(status)
      if (onLoading) {
        onLoading(status);
      }
    }, delay)
  }

  function onCanvasCreated(state) {
    state.gl.localClippingEnabled = true;
  }

  function onCameraUpdate(e) {
    if (cameraControlsRef.current) {
      if (distanceCount.current > 8) {
        startDistance.current = e.target.distance;
        distanceCount.current = 0;
      } else {
        distanceCount.current += 1;
      }
      handleOnZoom(e.target.distance);
    }
  }

  const handleOnZoom = (distance)  => {
    if (distance > startDistance.current) {
      endDistance.current = startDistance.current;
    }
    if (endDistance.current !== 0 && (distance > endDistance.current && distance - endDistance.current > 0.5)) {
      setZoomOut();
    }
  };

  const setZoomOut = debounce(function() {
    endDistance.current = 0;
    cameraControlsRef.current.setTarget(0, 0, 0, true);
    cameraControlsRef.current.dolly(-10, true);
    setIsFocusSpolier(false);
    if (updateFocusState) {
      updateFocusState(0);
    }
  }, 200);

  function onClickSpoiler(object) {
    cameraControlsRef.current.setLookAt( object.position.x + 2, object.position.y, object.position.z,
      (object.position.x + 2) / 2, object.position.y / 2, 0,
      true
    )

  }
  function onLookAtSpoiler(object) {
    cameraControlsRef.current.dolly(3, true);
    cameraControlsRef.current.setTarget(
      (object.position.x + 2) / 2, object.position.y / 2, 0,
      true
    )
  }
  function handleOnFocusSpolier(status) {
    if (status) {
      setIsFocusSpolier(true)
    } else {
      setIsFocusSpolier(false)
      if (cameraControlsRef.current) {
        cameraControlsRef.current.setTarget(0, 0, 0, true);
        cameraControlsRef.current.dolly(-4, true);
      }
    }
  }

  // function handleResetCamera() {
  //   if (cameraControlsRef.current) {
  //     cameraControlsRef.current.reset(true)
  //     resetCamera();
  //   }
  // }

  return (
    <div ref={canvasRef} className="relative Configurator">
      <div className={'loading-overlay' + (showDelayLoading ? ' show' : '')}>
        <img src={stollLogo} alt="stollLogo" />
      </div>
      <Suspense fallback={<LoadingScreen />}>
        <Canvas
          gl={{
            antialias: true,
            toneMappingExposure: 0.7,
            pixelRatio: Math.min(window.devicePixelRatio, 2),
          }}
          shadows
          id="three-canvas"
          onCreated={onCanvasCreated}
        >
          <PerspectiveCamera
            ref={camRef}
            fov={isMobileAndTabletControlVertical ? 45 : 29}
            position={
              isMobileAndTabletControlVertical
              // x: , y: , z: }
                ? [-9.708949167153316, 0.8967611230370927, 6.995150145482383]
                : [-5.482512125072488, 0.8589281211921728, 10.640251111654369]
            }
            makeDefault
          />
          <group
            position={[
              0,
              isMobileAndTabletHorizontal ? -0.6 : -0.4,
              0
            ]}
          >
            <Car
              carId={CAR_OPTIONS_LIST[selectedCarIndex].key}
              modelFile={CAR_OPTIONS_LIST[selectedCarIndex].file}
              suspension={carSuspension}
              color={carColor}
              partDisplayData={partDisplayData}
              selectedPaint={selectedPaint}
              isShowOemSpoiler={isShowOemSpoiler}
              isFocusSpolier={isFocusSpolier}
              onClickSpoiler={onClickSpoiler}
              onLookAtSpoiler={onLookAtSpoiler}
            />
          </group>
          <hemisphereLight intensity={0.5} />
          <Environment
            background={false} // Whether to affect scene.background
            files={HDRI_LIST['canary_wharf']}
          />
          <CameraControls
            ref={cameraControlsRef}
            minPolarAngle={1.1}
            maxPolarAngle={Math.PI / 2.08}
            minDistance={isMobileAndTabletControlVertical ? 3.2 : 3.78}
            maxDistance={isMobileAndTabletControlVertical ? 13 : 12}
            dollyToCursor={true}
            dollySpeed={0.5}
            smoothTime={0.09}
            draggingSmoothTime ={0.15}
            truckSpeed={2}
            onChange={onCameraUpdate}
          />
        </Canvas>
      </Suspense>
    </div>
  );
}

Configurator.propTypes = {
  'showFlic': PropTypes.bool,
  'showSill': PropTypes.bool,
  'showFronteinsatze': PropTypes.bool,
  'showFrontSpiltter': PropTypes.bool,
  'showDiffusorSide': PropTypes.bool,
  'showDiffusor':  PropTypes.bool,
  'showSpoiler':  PropTypes.bool,
  'focusState': PropTypes.number,
  'updateFocusState': PropTypes.func,
  // 'resetCamera': PropTypes.func,
  'onLoading': PropTypes.func,
  'carId': PropTypes.string,
};

export default Configurator;